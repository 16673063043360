import React from "react"
import PropTypes from "prop-types"
// import styled from 'styled-components'
// import { Box, Text } from 'rebass'

// const TabsContainer = styled.div``

// const TabsHeader = styled.header`
//   position: relative;
//   z-index: 10;
//   pointer-events: none;
//   h1,
//   h2 {
//     margin-top: 0;
//     margin-bottom: 30px;
//   }
//   nav {
//     margin-top: 0;
//     margin-bottom: 0;
//     pointer-events: all;
//   }
// `

// const TabsContent = styled.footer`
//   padding-top: 100px;
//   padding-bottom: 100px;
//   background-image: linear-gradient(-180deg, #1c1c1c 25px, #000000 250px);
// `

// export const TabElement = styled.a`
//   display: inline-block;
//   padding: 33px 50px;
//   font-weight: 600;
//   font-size: 15px;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   text-decoration: none;
//   color: ${Theme.colors.grays[4]};
//   margin-left: 10px;
//   cursor: pointer;
//   &.active {
//     background-image: linear-gradient(-180deg, #000000 20%, #1c1c1c 100%);
//     border-top: 3px solid ${Theme.colors.green};
//     color: white;
//   }
//   ${media.xs`
//     display: ${props => !props.mobile ? 'none' : 'inline-block' }
//   `}
// `

// const TabContent = styled.div`
//   display: none;
//   flex: 0 0 100%;
//   max-width: 100%;
//   &.active {
//     display: block;
//   }
// `

class Tabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
    }
    this.setActive = this.setActive.bind(this)
  }

  // active
  activeTab(index) {
    return index === this.state.tabIndex ? `active` : ``
  }

  setActive(index) {
    this.setState({
      tabIndex: index,
    })
  }

  render() {
    return (
      <div className="tabs">
        <header className="tabs__header mb-5">
          <nav className="tabs__nav">
            <ul className="is-unstyled d-flex">
              <li>{this.props.title}</li>
              {this.props.tabs.map((tab, key) => (
                <li>
                  <a
                    key={key}
                    onClick={e => this.setActive(key)}
                    className={`tabs__nav__item ${this.activeTab(key)}`}
                    aria-hidden="true"
                    role="button"
                  >
                    {tab}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </header>

        <footer className="tabs__contents">
          {this.props.contents.map((content, key) => (
            <div
              key={key}
              className={`tabs__contents__content ${this.activeTab(key)}`}
            >
              {content}
            </div>
          ))}
        </footer>
      </div>
    )
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  title: PropTypes.string,
  contents: PropTypes.array.isRequired,
}

export default Tabs
