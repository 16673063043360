import React, { Component } from "react"
import { graphql, Link } from 'gatsby'
import _ from "lodash"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import Tabs from "../../components/Tabs"
import Text from "../../content/texts/Text"

import QaPreview from "./QaPreview"

class QaIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.allQaSortedByOffices = []
    this.allQaSortedByName = this.props.data.allQA

    // console.log(this.props.data);

    // sort by office
    this.props.data.offices.edges.map((office, key) => {
      if (office.node.q_a) {
        this.allQaSortedByOffices.push(office)
      }
    })
  }

  sortByName() {
    return (
      <div className="row">
        {this.allQaSortedByName.edges.map((qa, index) => (
          <QaPreview
            key={index}
            index={new Date()}
            qa={qa.node}
            showOffice={true}
            col={`col-6 col-md-4 col-lg-3 mb-3`}
          />
        ))}
      </div>
    )
  }

  sortByOffices() {
    return (
      <div className="row">
        {this.allQaSortedByOffices.map((office, index) => (
          <div className="row qa_index__office_row col-12">
            <h3 className="col-12">{office.node.officeName}</h3>
            {office.node.q_a.map((qa, key) => (
              <QaPreview
                key={key}
                index={new Date()}
                qa={qa}
                showOffice={false}
                col={`col-6 col-md-4 col-lg-3 mb-3`}
              />
            ))}
          </div>
        ))}
      </div>
    )
  }

  render() {
    return (
      <Layout>
        <SEO title={`Q&A`} description={`All Q&A`} />
        <section className="qa_index container-fluid">
          <header className="single__header">
            <p className="h4 text-center">
              <Link to="/culture/">Culture</Link>
            </p>
          </header>

          {/* top header intro */}
          <div className="row">
            <header className="qa_index__header_intro">
              {/* intro text */}
              <hgroup className="row mb-4">
                <h1 className="col-12">Q&A</h1>
                <aside className="col-12 col-md-8 ml-auto mr-auto">
                  <Text text={this.props.data.allQAIntro} container={false} />
                </aside>
              </hgroup>
            </header>
          </div>

          <Tabs
            title={`Sort by`}
            tabs={[`Name`, `Office`]}
            contents={[this.sortByName(), this.sortByOffices()]}
          />
        </section>
      </Layout>
    )
  }
}

export default QaIndex

export const query = graphql`
  query QaIndex {
    offices: allContentfulOffices(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "en-US" }, venture: { eq: false } }
    ) {
      edges {
        node {
          slug
          officeName
          shortName
          q_a {
            ...qaFragment
          }
        }
      }
    }
    allQAIntro: contentfulTexts(slug: { eq: "all-qa-intro" }) {
      ...textFragment
    }
    allQA: allContentfulQa(
      sort: { fields: [title], order: ASC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...qaFragment
        }
      }
    }
  }
`
