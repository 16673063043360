import React, { Component } from "react"
import Link from "gatsby-link"
import FigureBox, { boxFormats } from "../../components/FigureBox"

class QaPreview extends Component {
  constructor(props) {
    super(props)
  }

  style() {
    return {
      backgroundColor: this.props.qa.headerColor
        ? this.props.qa.headerColor
        : `#000`,
    }
  }

  render() {
    return (
      <div className={`qa__preview ${this.props.col}`}>
        <Link to={`/culture/q&a/${this.props.qa.slug}`}>
          <header style={this.style()}>
            {this.props.qa.headerImage && (
              <FigureBox
                format={boxFormats.portrait}
                type="background"
                source={this.props.qa.headerImage}
              />
            )}
            {!this.props.qa.headerImage && (
              <div className={boxFormats.portrait} />
            )}
          </header>
          {this.props.qa.contact && (
            <footer className="mt-2">
              <h4>{this.props.qa.contact.contactFullName}</h4>
              {this.props.showOffice &&
                this.props.qa.office && (
                  <h6>{this.props.qa.office.officeName}</h6>
                )}
            </footer>
          )}
        </Link>
      </div>
    )
  }
}

QaPreview.defaultProps = {
  col: ``,
  showOffice: false,
}

export default QaPreview
